import { Component, Injectable, OnInit, TemplateRef } from '@angular/core';
import * as _ from 'underscore';
import { MetricTile, PCGMetrics } from '@mint-libs/components';
import { BaseViewComponent } from '../shared/base/base-view.component';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { LoggerService } from '../core/logging/logger.service';
import { Formatter } from '@mint-libs/common';
import { GetPayoutSummary } from '../shared/shared-dashboard/payout-summary/state/payout-summary.actions';
import { GetTargetSummary } from '../shared/shared-dashboard/target-summary/state/target-summary.actions';
import { GetPaylineSummary } from '../shared/shared-dashboard/payline-summary/state/payline-summary.actions';
import { GetRefreshSummary } from '../shared/shared-dashboard/refresh-summary/state/refresh-summary.actions';
import { PaylineSummaryState } from '../shared/shared-dashboard/payline-summary/state/payline-summary.model';
import { selectConfigurationState } from '@mint-libs/configuration';
import { PayoutSummaryState } from '../shared/shared-dashboard/payout-summary/state/payout-summary.model';
import { selectPayoutWithFilterLabels } from '../shared/shared-dashboard/payout-summary/state/payout-summary.selectors';
import { selectUserProfileState } from '@mint-libs/context';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mint-mobile-seller-dashboard',
  templateUrl: './base-seller-dashboard.component.html',
  styleUrls: ['./base-seller-dashboard.component.scss']
})
export class BaseSellerDashboardComponent extends BaseViewComponent implements OnInit {
  userProfile: any;
  ytdPaymentKPI: MetricTile;
  projectedPaymentKPI: MetricTile;
  sumOfYTDPayments: any = 0;
  payoutTilesLoaded: boolean;
  attainmentTileLoaded: boolean;
  paylinesLoaded: boolean;
  ytdPCGs: PCGMetrics[];
  config: any;
  isPayoutUnavailable: boolean;
  metricCardConfig: any = {
    shouldShowTitle: true,
    shouldShowWeight: true,
    shouldShowAttainment: true,
    shouldShowEarnings: true,
    shouldShowTimeSpan: false
  };
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, arrows: true, centerMode: true, centerPadding: '10px', waitForAnimate: false, focusOnChange: true };
  selectedTab = 0;
  modalRef;

  constructor(
    protected appState: Store<AppState>,
    protected formatter: Formatter,
    protected logger: LoggerService,
    protected paylineSummaryState: Store<PaylineSummaryState>,
    protected payoutSummaryState: Store<PayoutSummaryState>,
    protected modalService: NgbModal
  ) {
    super(appState, logger);
    this.appState.select(selectUserProfileState).subscribe(userProfileState => {
      if (!userProfileState) {
        return;
      }
      this.userProfile = userProfileState;
    });
  }

  ngOnInit() { }

  resetKPIs() {
    this.ytdPaymentKPI = new MetricTile();
    this.projectedPaymentKPI = new MetricTile();
    this.ytdPaymentKPI.isMobile = true;
    this.projectedPaymentKPI.isMobile = true;
    this.payoutTilesLoaded = false;
    this.attainmentTileLoaded = false;
    this.paylinesLoaded = false;
  }

  loadData() {
    this.appState.dispatch(new GetPayoutSummary(null));
    this.appState.dispatch(new GetTargetSummary(null));
    this.appState.dispatch(new GetPaylineSummary(null, null));
    this.appState.dispatch(new GetRefreshSummary(null));
  }

  loadKPIs() {
    this.payoutSummaryState.select(selectPayoutWithFilterLabels).subscribe(payoutWithfilterLabel => {
      const payoutSummary = payoutWithfilterLabel.payoutSummary;
      if (payoutSummary && payoutSummary.applicablePayouts) {
        this.payoutTilesLoaded = true;
        this.setYtdPayoutAndNextPayoutKPI(payoutSummary, payoutWithfilterLabel.fiscalYearLabel);
      }
    });
  }

  setYtdPayoutAndNextPayoutKPI(payoutSummary: any, fiscalYearLabel: string) {
    this.ytdPaymentKPI = new MetricTile();
    this.projectedPaymentKPI = new MetricTile();
    this.ytdPaymentKPI.iconName = 'done_all';
    this.projectedPaymentKPI.iconName = 'hourglass_empty';
    this.sumOfYTDPayments = 0;
    const isOptedOut = this.isOptedOut;

    // this.ytdPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconDoubleTick);
    // this.projectedPaymentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconHourGlass);

    const publishedPayouts = payoutSummary.applicablePayouts.filter(payout => payout.paymentStatus === 'Published');

    const lastPublished = publishedPayouts.length > 0 ? publishedPayouts[publishedPayouts.length - 1] : null;

    const nonPublishedPayouts = payoutSummary.applicablePayouts.filter(
      payout => payout.paymentStatus === 'Not Published' && (lastPublished == null || payout.partition.stepId > lastPublished.partition.stepId)
    );

    const firstNonPublished = nonPublishedPayouts.length > 0 ? nonPublishedPayouts[0] : null;
    const lastNonPublished = nonPublishedPayouts.length > 0 ? nonPublishedPayouts[nonPublishedPayouts.length - 1] : null;

    publishedPayouts.forEach(payout => {
      this.sumOfYTDPayments += payout.payrollPayment;
    });

    this.ytdPaymentKPI.amount = '0.00';
    this.ytdPaymentKPI.amountTitle = `No Payments are made till now in ${fiscalYearLabel}`;
    this.ytdPaymentKPI.isMobile = true;
    this.projectedPaymentKPI.amount = 'N/A';
    this.projectedPaymentKPI.amountTitle = `No more on-cycle payments in ${fiscalYearLabel}`;
    this.projectedPaymentKPI.isMobile = true;

    if (lastPublished !== null) {
      this.ytdPaymentKPI.fiscalQuarter = `(${lastPublished.codePeriod})`;
      this.ytdPaymentKPI.currencyCode = lastPublished.isCountryChange ? lastPublished.transferredCurrencyCode : lastPublished.currencyCode;
      this.ytdPaymentKPI.amountTitle = `YTD Payments in ${this.ytdPaymentKPI.currencyCode} (${fiscalYearLabel})`;
      this.ytdPaymentKPI.amount = this.formatter.formatAndRoundOff(this.sumOfYTDPayments);
      if (lastPublished.isCountryChange) {
        this.ytdPaymentKPI.amount = this.formatter.formatAndRoundOff(lastPublished.transferredCurrencyAmount);
      }
    }

    const nextNonPublished = isOptedOut ? lastNonPublished : firstNonPublished;

    if (nextNonPublished !== null) {
      this.projectedPaymentKPI.fiscalQuarter = `(${nextNonPublished.codePeriod})`;
      this.projectedPaymentKPI.currencyCode = nextNonPublished.isCountryChange ? nextNonPublished.transferredCurrencyCode : nextNonPublished.currencyCode;
      this.projectedPaymentKPI.amount = this.formatter.formatAndRoundOff(nextNonPublished.netPaymentAmount) !== '0' ? this.formatter.formatAndRoundOff(nextNonPublished.netPaymentAmount) : '0.00';
      this.projectedPaymentKPI.amountTitle = `Next Projected Payment in ${this.projectedPaymentKPI.currencyCode} ${this.projectedPaymentKPI.fiscalQuarter}`;
    } else {
      // TODO: to be removed once WWIC confirms that payment has been recieved by sellers
      if (lastPublished !== null) {
        this.projectedPaymentKPI.fiscalQuarter = `(${lastPublished.codePeriod})`;
        this.projectedPaymentKPI.currencyCode = lastPublished.isCountryChange ? lastPublished.transferredCurrencyCode : lastPublished.currencyCode;
        this.projectedPaymentKPI.amount = this.formatter.formatAndRoundOff(lastPublished.netPaymentAmount) !== '0' ? this.formatter.formatAndRoundOff(lastPublished.netPaymentAmount) : '0.00';
      }
    }

    if (lastPublished === null) {
      this.ytdPaymentKPI.amount = '0.00';
      this.ytdPaymentKPI.amountTitle = `YTD Payments in ${this.projectedPaymentKPI.currencyCode}  (${fiscalYearLabel})`;
      this.ytdPaymentKPI.currencyCode = this.projectedPaymentKPI.currencyCode;
    }

    if (isOptedOut) {
      this.ytdPaymentKPI.amountTitle = `Opted out of advance payments (${fiscalYearLabel})`;
    }
  }

  getConfigData() {
    this.appState.select(selectConfigurationState).subscribe(state => {
      if (!state) {
        return;
      }
      this.config = state;
      this.isPayoutUnavailable = this.config.isPayoutUnavailable && this.config.configs.latestFiscalYear === this.selectedFiscalYear;
    });
  }

  slickInit() {
    console.log('slick initialized');
  }

  breakpoint() {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
    this.setActiveSlide(e.currentSlide);
  }

  setActiveSlide(slideIndex) {
    this.ytdPCGs.forEach((element, index) => {
      element.isActive = index === slideIndex ? true : false;
    });
  }

  beforeChange() {
    console.log('beforeChange');
  }

  dragStart() {
    console.log('clicked in');
  }
  dragEnd() {
    console.log('clicked out');
  }
}
