<div *ngIf="!isLoaded"><mint-content-loader-large></mint-content-loader-large></div>
<div *ngIf="isLoaded">
  <div *ngIf="isLivePPA" class="live-ppa-container">
    <!-- <iframe style="display: block;" [src]="responseFromAPI" title="PPA Document" width="100%" height="100%"></iframe> -->
  </div>

  <div *ngIf="isPPAShown" style="font-size: 12px;">
    <div aria-live="polite" tabindex="-1">
      <div class="text-center" *ngIf="isDemoMode2">PPA document is not available in demo mode</div>
      <div class="text-center" *ngIf="isPPASigned">
        You've already signed this document on {{ complianceService.signedDate }}. No further action regarding this document is required from you at this time.
      </div>
      <div class="text-center" *ngIf="isPPANotSigned">
        Plan Participant Agreement for this participation is not yet signed by the Seller. You will be able to see this document once it is signed. Please check later.
      </div>
      <div class="text-center" *ngIf="isPPANotAvailable">
        Plan Participant Agreement for this participation is not yet available. Please check later.
      </div>
    </div>
  </div>
  <div *ngIf="isOfflinePPA">
    <ngx-extended-pdf-viewer
      [src]="blobURL"
      [textLayer]="false"
      [pageViewMode]="single"
      [enablePinchOnMobile]="true"
      [backgroundColor]="white"
      [showBookmarkButton]="false"
      [mobileFriendlyZoom]="true"
      [showZoomButtons]="false"
      [useBrowserLocale]="true"
      [minifiedJSLibraries]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
</div>
