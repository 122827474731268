<div *ngIf="h2QuotaMessage && !quotaService.isH2MessageHidden" class="footer_content__h2Message">{{ h2QuotaMessage }}</div>
<div *ngIf="(!complianceService.signedDate && !isImpersonating) || canSignOnBehalf">
  <div class="footer_content__unacknowledged">
    You can postpone Quota Acknowledgement if you need more time. Please note that you will not have access to your dashboard till you acknowledge your Quota.
  </div>
  <div class="footer-btn-container">
    <!-- <button
      type="button"
      title="I Acknowledge"
      class="footer__btn footer__btn-primary"
      (click)="acknowledge()"
      (keyup.enter)="acknowledge()"
      analytics-tracker
      [tracker]="'Quota_Acknowledge_btn'"
      [value]="'I Acknowledge'"
    >
      I Acknowledge
    </button>
    <button
      type="button"
      title="I Accept"
      class="footer__btn footer__btn-cancel"
      (click)="postpone()"
      (keyup.enter)="postpone()"
      analytics-tracker
      [tracker]="'Quota_Postpone_btn'"
      [value]="'Postpone'"
    >
      Postpone
    </button> -->
  </div>
</div>
<div *ngIf="isImpersonating && !canSignOnBehalf && !complianceService.signedDate" class="footer_content__admin-unacknowledged">Document not Signed</div>
